.d-creditCardForm {
  position: relative;

  &__cardInput iframe {
    height: 56px !important;
    // margin-bottom: 20px;
  }

  .ant-space-item {
    margin: 0 auto;
  }

  &__saveBtn.d-blueBtn {
    width: 330px;
    margin: 40px auto 0;

    @media @s {
      width: 100%;
    }
  }
}
