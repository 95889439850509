.d-contactUsPage {
  position: relative;
  margin-bottom: 90px;

  .d-titleWithBg {
    margin-bottom: 65px;
  }

  .d-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 30px;

    @media @m {
      align-items: flex-start;
    }

    @media @mm {
      flex-direction: column;
    }
  }

  &__links {
    &-link,
    a {
      color: @darkBlue;
      font-size: 20px;
      font-weight: 600;

      display: flex;
      align-items: center;
      gap: 24px;

      @media @s {
        font-size: 14px;
      }

      &:not(:last-of-type) {
        margin-bottom: 55px;

        @media @s {
          margin-bottom: 20px;
        }
      }

      &:hover,
      &:focus {
        color: @darkBlue;
      }
    }

    span > svg {
      @media @sm {
        width: 48px;
        height: 48px;
      }
    }
  }

  &__form {
    width: 100%;
    max-width: 750px;

    @media @mm {
      max-width: 100%;
    }
    &-inputText.d-input .ant-input {
      border-radius: 12px;
      padding: 20px 24px;
    }
  }

  &__saveBtn.d-blueBtn {
    width: 230px;
    margin-left: auto;

    @media @s {
      width: 100%;
    }
  }
}
