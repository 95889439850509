.d-signUp {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    display: block;
    top: -30px;
    left: -60px;
    // bottom: -30px;
    height: 600px;
    right: -60px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='490' height='600' viewBox='0 0 490 600' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cmask id='mask0_1925_25268' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='490' height='600'%3e%3cpath d='M0 38.4C0 24.9587 0 18.2381 2.61584 13.1042C4.9168 8.58834 8.58834 4.9168 13.1042 2.61584C18.2381 0 24.9587 0 38.4 0H451.6C465.041 0 471.762 0 476.896 2.61584C481.412 4.9168 485.083 8.58834 487.384 13.1042C490 18.2381 490 24.9587 490 38.4V561.6C490 575.041 490 581.762 487.384 586.896C485.083 591.412 481.412 595.083 476.896 597.384C471.762 600 465.041 600 451.6 600H38.4C24.9587 600 18.2381 600 13.1042 597.384C8.58834 595.083 4.9168 591.412 2.61584 586.896C0 581.762 0 575.041 0 561.6V38.4Z' fill='white'/%3e%3c/mask%3e%3cg mask='url(%23mask0_1925_25268)'%3e%3cg opacity='0.2'%3e%3cpath d='M-483.853 543.782C-425.712 538.865 -287.334 503.838 -198.942 403.063C-88.4525 277.094 -366.429 256.374 -174.474 140.418C17.4812 24.4631 127.389 143.171 197.876 -96.6591C268.362 -336.49 477.124 -94.0258 585.447 -228.057' stroke='%23161962'/%3e%3cpath d='M-455.469 560.022C-397.329 555.105 -258.95 520.078 -170.558 419.303C-60.0687 293.334 -338.045 272.614 -146.09 156.659C45.865 40.7033 155.773 159.412 226.259 -80.4189C296.746 -320.249 505.508 -77.7856 613.831 -211.817' stroke='%2330E986'/%3e%3cpath d='M-427.085 576.261C-368.945 571.344 -230.566 536.316 -142.175 435.541C-31.685 309.573 -309.661 288.852 -117.706 172.897C74.2488 56.9416 184.157 175.65 254.643 -64.1806C325.13 -304.011 533.891 -61.5473 642.215 -195.579' stroke='%23E9BE2F'/%3e%3cpath d='M-398.701 592.501C-340.561 587.584 -202.182 552.557 -113.791 451.782C-3.30117 325.813 -281.277 305.092 -89.3223 189.137C102.633 73.1818 212.54 191.89 283.027 -47.9404C353.513 -287.771 562.275 -45.3071 670.598 -179.338' stroke='%23FE01CF'/%3e%3c/g%3e%3c/g%3e%3c/svg%3e ");
  }

  &__checkbox.ant-checkbox-wrapper {
    position: relative;
    color: @black;
    font-size: 12px;

    &:hover {
      .ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner {
        background: rgba(213, 221, 224, 0.5);
      }

      .ant-checkbox-checked:not(.ant-checkbox-disabled):after {
        border: none;
      }
    }

    .ant-checkbox-checked:after {
      border: none;
    }

    .ant-checkbox {
      min-width: 24px;
      min-height: 24px;

      .ant-checkbox-inner {
        min-width: 24px;
        min-height: 24px;
        background: rgba(213, 221, 224, 0.5);
        border-radius: 50%;
        border: none;

        &::after {
          position: absolute;
          top: 50%;
          left: 50%;
          width: 12px;
          height: 10px;
          border: none;
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
          transform: rotate(0deg) translate(-50%, -50%);
          background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='12' height='10' viewBox='0 0 12 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M11 1L4.125 9L1 5.36364' stroke='%231a3c68' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e ");
        }
      }
    }

    .ant-checkbox + span {
      padding-inline: 8px 0;
    }
  }
}
