* {
  font-family: 'Poppins', sans-serif;
}

.d-wrapper {
  max-width: 1360px;
  margin: 0 auto;

  @media @xl {
    max-width: 100vw;
    padding: 0 20px;
  }

  @media @m {
    max-width: 100vw;
    padding: 0 20px;
  }

  @media @mm {
    &.mobile {
      padding: 0;
    }
  }

  @media @s {
    padding: 0 16px;
  }

  // @media @sm {
  //   padding: 0 20px;
  // }

  &--header {
    max-width: 1530px;
    margin: 0 auto;
    padding: 0 20px;
  }

  &.no-padding {
    padding: 0;
  }
}

.d-titleWithBg {
  position: relative;
  background: #f5f8fd;
  width: 100%;
  padding: 32px 0;

  font-size: 30px;
  color: @darkBlue;
  font-weight: 700;
  text-align: center;

  margin-bottom: 50px;

  @media @s {
    padding-block: 16px;
    margin-bottom: 25px;
  }
}

.ant-input-affix-wrapper {
  box-shadow: none !important;
}

.d-loader {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  // background-color: @darkBlue;
  // opacity: 0.3;
  display: flex;
  justify-content: center;
  align-items: center;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    display: block;
    background-color: @darkBlue;
    opacity: 0.3;
  }

  &-item {
    position: relative;
    z-index: 20;
    .ant-spin-dot {
      .ant-spin-dot-item {
        background-color: @darkBlue;
      }
    }
  }
}

.ant-btn-primary.btn {
  background: linear-gradient(90deg, @darkBlue 34%, @lightBlue 100%);
  box-shadow: none;
  width: 330px;
  height: 55px;
  border-radius: 50px;
  border: none;
}

.d-whatsApp {
  width: 70px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  // position: sticky;
  bottom: 60px;
  right: auto;
  left: 94%;
  z-index: 11;

  padding: 0;
  border: none;

  @media @mm {
    left: 87%;
  }

  @media @s {
    left: 81%;

    span {
      svg {
        width: 40px;
        height: 40px;
      }
    }
  }
}

.ant-select-dropdown {
  .ant-select-item.ant-select-item-option {
    color: @darkBlue;

    &:hover,
    &.ant-select-item-option-active {
      background: rgba(255, 209, 103, 0.2);
    }

    &.ant-select-item-option-selected,
    &.ant-select-item-option-selected.ant-select-item-option-active {
      color: @darkBlue;
      background: rgba(255, 209, 103, 0.6) !important;
    }
  }

  .rc-virtual-list-scrollbar-thumb {
    background: linear-gradient(
      45deg,
      @yellow 9.74%,
      @orange 79.03%
    ) !important;
  }
}

.d-swiper-navigation {
  .swiper-button-next.d-swiper__next,
  .swiper-button-prev.d-swiper__prev {
    min-width: 48px;
    height: 48px;
    border-radius: 50%;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M9 5L16 12L9 19' stroke='%235D5F5F' stroke-width='1.8' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e ");
    background-repeat: no-repeat;
    background-position: center;
    border: 1px solid #e3e3e3;
    right: -70px;
    background-color: @white;
    transform: translateY(-50%);

    @media @xxl {
      right: -25px;
    }

    @media @xl {
      right: 10px;
    }
  }

  .swiper-button-prev.d-swiper__prev {
    transform: scale(-1, 1) translateY(-50%);
    right: initial;
    left: -70px;

    @media @xxl {
      left: -25px;
    }

    @media @xxl {
      left: 10px;
    }
  }
}

#popular-swiper-button-next,
#popular-swiper-button-prev,
#special-swiper-button-next,
#special-swiper-button-prev,
#related-swiper-button-next,
#related-swiper-button-prev {
  @media @mm {
    display: none;
  }
}

.d-emptyText {
  color: @darkBlue;
  font-weight: 600;
  font-size: 16px;
}

.d-modal {
  padding: 0;
  .ant-modal-content {
    padding: 0;
    border-radius: 24px;

    .ant-modal-body {
      padding: 30px 20px;

      @media @mm {
        padding: 20px 30px;
      }

      @media @s {
        padding: 30px 20px 10px;
      }
    }

    .ant-modal-close {
      width: 16px;
      height: 16px;
      top: 20px;
      right: 20px;

      @media @s {
        top: 14px;
        right: 14px;
      }

      &-x svg path {
        fill: @borderColor;
        transition: 0.2s all linear;
      }

      &:hover {
        background: transparent;

        .ant-modal-close-x svg path {
          fill: @darkBlue;
        }
      }
    }
  }

  &__title {
    position: relative;
    color: @darkBlue;
    text-align: center;
    font-size: 30px;
    font-weight: 700;
    margin: 0 0 12px;
  }

  &__desc {
    position: relative;
    color: @darkBlueOp7;
    text-align: center;
    font-size: 16px;
    line-height: 150%;
    margin-bottom: 20px;
  }

  &__btn.ant-btn {
    color: @white;
    text-align: center;
    font-size: 20px;
    font-weight: 700;

    display: flex;
    justify-content: center;
    align-items: center;

    height: 54px;
    border: none;
    box-shadow: none;
    width: 100%;
    border-radius: 50px;
    margin: 0 0 30px;
    background: linear-gradient(38deg, @darkBlue 20.99%, @lightBlue 90.32%);

    span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &:hover {
      color: @white;
    }
  }
}

.d-formAccount {
  &__wrapper {
    width: 100%;
    max-width: 750px;

    @media @mm {
      max-width: unset;
    }
  }

  &__title {
    color: @darkBlue;
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 20px;
  }

  &__borderedItem {
    position: relative;
    border: 1px solid @borderColor;
    padding: 20px;
    border-radius: 6px;

    &:not(:last-of-type) {
      margin-bottom: 20px;
    }

    &-titleRow {
      display: flex;
      justify-content: space-between;
      align-items: center;

      color: @darkBlue;
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 8px;
    }

    &-text {
      color: @black;
      font-size: 16px;
      line-height: 170%;
    }
  }

  &__btn.ant-btn {
    height: 48px;
    padding-inline: 14px;
    margin: 0;
    border: none;
    box-shadow: none;

    background: rgba(18, 18, 29, 0.05);
    border-radius: 30px;
    color: @darkBlue;
    font-size: 14px;
    font-weight: 700;

    &:hover {
      background: rgba(18, 18, 29, 0.05);
      color: @darkBlue;
    }
  }
}

.d-pagination.ant-pagination {
  width: max-content;
  margin: 40px auto 0;

  .ant-pagination-prev,
  .ant-pagination-next {
    background: @white;
    border: 1px solid @darkBlueOp7;
    border-radius: 50px;

    &.ant-pagination-disabled {
      opacity: 0.4;
    }

    a {
      color: @darkBlueOp7;
      padding: 0px 12px 6px;
    }

    .ant-pagination-item-link {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &:hover {
      .ant-pagination-item-link {
        background: transparent;
      }
    }
  }

  .ant-pagination-item {
    border: none;
    border-radius: 50%;

    a {
      color: @darkBlueOp7;
      margin-top: 2px;
    }

    &.ant-pagination-item-active {
      background: linear-gradient(90deg, @orange 9.74%, @yellow 79.03%);

      a {
        color: @white;
      }
    }
  }
}

.d-input.ant-form-item,
.d-inputPassword.ant-form-item {
  margin-bottom: 20px;

  .ant-form-item-explain-error {
    color: @red;
    font-size: 12px;
  }

  .ant-input {
    color: @black;

    &::placeholder {
      color: @mediumBlue;
    }

    &:focus {
      box-shadow: none;
    }
  }
}

.d-input.ant-form-item {
  .ant-input {
    border-radius: 50px;
    min-height: 56px;
    border: 1px solid @borderColor;
    padding-inline: 24px;
  }
}

.d-login__input.d-input__pass {
  border-radius: 50px;
  min-height: 56px;
  border: 1px solid @borderColor;
  padding-inline: 24px;
  margin-bottom: 20px;

  &:focus {
    box-shadow: none;
  }
}

.d-inputPassword.ant-form-item {
  .ant-input-affix-wrapper {
    &-focused {
      box-shadow: none;
    }
  }

  .ant-form-item-control-input {
    min-height: 56px;
  }

  .ant-input-password {
    border-radius: 50px;
    min-height: 56px;
    border: 1px solid @borderColor;
    padding-inline: 24px;
  }
}

.d-select {
  .ant-select .ant-select-selector {
    height: 56px;
    border-radius: 50px;
    border: 1px solid @borderColor;

    align-items: center;
    padding-inline: 24px;
  }

  .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(
      .ant-pagination-size-changer
    ):hover
    .ant-select-selector {
    border-color: @borderColor;
  }

  .ant-select-focused:not(.ant-select-disabled) .ant-select-selector {
    box-shadow: none !important;
    border-color: @borderColor !important;
  }

  .ant-select-arrow {
    inset-inline-end: 20px;
  }
}

.ant-tooltip {
  --antd-arrow-background-color: @white;

  & .ant-tooltip-inner {
    background: @white;
    color: #00876e;
    box-shadow: 0px 0px 15px 0px rgba(20, 41, 69, 0.15);
    padding: 5px 15px;
  }
}

.d-selectFilter {
  position: relative;

  &.first {
    .ant-select .ant-select-selector {
      background: transparent;
      .ant-select-selection-item {
        color: @white !important;
      }
    }
    .ant-select-arrow {
      svg path {
        fill: @white;
      }
    }
    .d-selectFilter-title {
      color: @white;
    }
  }

  .ant-select {
    // min-width: 125px;
    .ant-select-selector {
      border-radius: 18px;
      height: 37px;
      justify-content: space-between;
      align-items: center;
      background: @white;
      border: 1px solid @darkBlue !important;
      color: @darkBlue;

      box-shadow: none !important;

      .ant-select-selection-item {
        text-align: end;
        font-weight: 600;
        padding-left: 90px;
      }
    }
  }

  &-title {
    position: absolute;
    top: 9px;
    left: 20px;
    color: @darkBlueOp7;
  }
}

.d-blueBtn.ant-btn {
  position: relative;
  height: 54px;
  padding-inline: 24px;
  border: none;
  box-shadow: none;
  width: 100%;

  border-radius: 50px;
  background: linear-gradient(38deg, @darkBlue 20.99%, @lightBlue 90.32%);

  color: @white;
  font-size: 20px;
  font-weight: 700;

  display: flex;
  justify-content: center;
  align-items: center;

  span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &:hover,
  &:focus {
    color: @white;
    background: linear-gradient(38deg, @darkBlue 20.99%, @lightBlue 90.32%);
  }

  &:disabled {
    opacity: 0.4;
  }
}

.d-home__faq-item {
  margin: 20px 0;
}
