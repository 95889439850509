.d-login {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    display: block;
    // top: -30px;
    left: -60px;
    bottom: -30px;
    height: 600px;
    right: -60px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='490' height='600' viewBox='0 0 490 600' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cg opacity='0.2'%3e%3cpath d='M-586.381 425.105C-546.841 468.012 -434.307 555.829 -300.5 563.842C-133.24 573.857 -287.354 341.588 -78.042 422.091C131.27 502.593 104.913 662.207 337.574 570.796C570.234 479.384 506.755 792.977 679.054 796.336' stroke='%23161962'/%3e%3cpath d='M-581.801 457.484C-542.261 500.391 -429.727 588.208 -295.92 596.221C-128.66 606.236 -282.774 373.967 -73.4619 454.47C135.85 534.972 109.494 694.586 342.154 603.175C574.814 511.763 511.335 825.356 683.634 828.715' stroke='%2330E986'/%3e%3cpath d='M-577.219 489.861C-537.678 532.768 -425.145 620.585 -291.338 628.598C-124.078 638.613 -278.192 406.344 -68.8799 486.847C140.432 567.349 114.076 726.963 346.736 635.551C579.396 544.14 515.917 857.733 688.216 861.092' stroke='%23E9BE2F'/%3e%3cpath d='M-572.639 522.24C-533.098 565.147 -420.565 652.964 -286.758 660.977C-119.498 670.992 -273.612 438.723 -64.2998 519.225C145.013 599.728 118.656 759.342 351.316 667.93C583.976 576.519 520.497 890.111 692.796 893.471' stroke='%23FE01CF'/%3e%3c/g%3e%3c/svg%3e ");
  }

  &__socialBtns {
    position: relative;
    display: flex;
    justify-content: center;
    gap: 30px;
    margin-bottom: 24px;

    .ant-btn-text {
      padding: 0;
      border: none;
      height: 50px;

      &:hover {
        background: transparent;
      }
    }
  }

  &__divider {
    position: relative;
    color: @black;
    text-align: center;
    font-size: 14px;
    line-height: 150%;
    margin-bottom: 20px;

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;

    &::before,
    &::after {
      content: '';
      display: inline-block;
      width: 85px;
      height: 1px;
      background: #d5dde0;
    }
  }

  &__signUp {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;

    &-text,
    &-href {
      color: @black;
      font-size: 14px;
      text-decoration: none;
    }

    &-href.ant-btn {
      background: transparent;
      box-shadow: none;
      font-weight: 600;
      color: @darkBlue;
      padding: 0;

      &:hover,
      &:focus {
        background: transparent;
        color: @darkBlue;
      }
    }
  }

  &__rememberBtn.ant-btn {
    color: #eaac41;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    line-height: 150%;

    padding: 0;
    margin: 0 0 40px;

    &:hover {
      color: @darkBlue;
    }
  }

  &-rememberPass {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      display: block;
      top: -30px;
      left: -60px;
      bottom: -30px;
      right: -60px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='490' height='390' viewBox='0 0 490 390' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cmask id='mask0_1_6' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='490' height='390'%3e%3cpath d='M0 38.4C0 24.9587 0 18.2381 2.61584 13.1042C4.9168 8.58834 8.58834 4.9168 13.1042 2.61584C18.2381 0 24.9587 0 38.4 0H451.6C465.041 0 471.762 0 476.896 2.61584C481.412 4.9168 485.083 8.58834 487.384 13.1042C490 18.2381 490 24.9587 490 38.4V351.6C490 365.041 490 371.762 487.384 376.896C485.083 381.412 481.412 385.083 476.896 387.384C471.762 390 465.041 390 451.6 390H38.4C24.9587 390 18.2381 390 13.1042 387.384C8.58834 385.083 4.9168 381.412 2.61584 376.896C0 371.762 0 365.041 0 351.6V38.4Z' fill='white'/%3e%3c/mask%3e%3cg mask='url(%23mask0_1_6)'%3e%3cg opacity='0.2'%3e%3cpath d='M688.041 -114.896C648.5 -71.9887 535.967 15.8286 402.16 23.8409C234.9 33.8562 389.014 -198.413 179.702 -117.91C-29.6106 -37.4078 -3.2537 122.207 -235.914 30.7947C-468.575 -60.6171 -405.095 252.976 -577.394 256.335' stroke='%23161962'/%3e%3cpath d='M683.461 -82.5169C643.92 -39.6098 531.387 48.2074 397.579 56.2197C230.32 66.2351 384.434 -166.034 175.122 -85.5314C-34.1907 -5.02892 -7.83384 154.585 -240.494 63.1736C-473.155 -28.2382 -409.675 285.355 -581.974 288.714' stroke='%2330E986'/%3e%3cpath d='M678.88 -50.1383C639.339 -7.23125 526.806 80.586 392.998 88.5983C225.739 98.6136 379.853 -133.655 170.54 -53.1528C-38.7719 27.3496 -12.415 186.964 -245.075 95.5521C-477.736 4.14033 -414.256 317.733 -586.555 321.093' stroke='%23E9BE2F'/%3e%3cpath d='M674.3 -17.7595C634.759 25.1476 522.226 112.965 388.418 120.977C221.159 130.992 375.273 -101.276 165.96 -20.774C-43.352 59.7285 -16.9951 219.343 -249.656 127.931C-482.316 36.5191 -418.836 350.112 -591.135 353.471' stroke='%23FE01CF'/%3e%3c/g%3e%3c/g%3e%3c/svg%3e ");
    }

    &__btn.d-modal__btn {
      margin-bottom: 0;
    }

    &__timer {
      position: relative;
      text-align: center;
      margin-bottom: 30px;
      color: @darkBlueOp7;
    }
  }
}
