.d-commentItem {
  position: relative;
  display: flex;
  gap: 20px 30px;

  @media @s {
    flex-flow: column;
  }

  &:not(:last-of-type) {
    margin-bottom: 50px;
  }

  &__avatar.ant-avatar {
    min-width: 95px;
    height: 95px;
    object-fit: cover;
    border: none;
  }

  &__info {
    margin-top: 8px;

    @media @s {
      margin-top: 0;
    }

    &-title {
      color: rgba(18, 18, 29, 0.7);
      font-size: 20px;
      font-weight: 500;
      margin-bottom: 18px;

      @media @s {
        margin-bottom: 10px;
      }
    }

    &-desc {
      color: @black;
      line-height: 140%;
      margin-bottom: 15px;
    }

    &-replyBtn.ant-btn {
      color: @darkBlue;
      font-weight: 600;
      text-transform: uppercase;
      padding: 0;
      border: none;
      box-shadow: none;
      background: transparent;

      &:hover,
      &:focus {
        background: transparent;
        color: @darkBlue;
      }
    }
  }
}
