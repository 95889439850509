.d-categoriesItem {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 4px;

  &.active {
    .ant-image,
    .d-categoriesItem__avatar.ant-skeleton.ant-skeleton-element {
      &::before {
        content: '';
        position: absolute;
        display: block;
        top: -4px;
        left: -4px;
        right: -4px;
        bottom: -4px;
        width: calc(~'100% + 8px');
        height: calc(~'100% + 8px');
        border-radius: 50%;
        background: linear-gradient(90deg, @orange 9.74%, @yellow 79.03%);
      }
    }
    .d-categoriesItem__avatar,
    .d-categoriesItem__avatar .ant-skeleton-avatar {
      border: none;
    }
  }

  .ant-image {
    margin-bottom: 12px;
  }

  .ant-image-error .d-categoriesItem__avatar.ant-image-img {
    object-fit: cover;
  }

  &__avatar {
    position: relative;
    border: 1px solid @borderColor;
    box-shadow: -4px 4px 10px rgba(26, 58, 77, 0.1);
    border-radius: 50px;
    padding: 8px;

    @media @s {
      padding: 0;
    }

    &.ant-skeleton.ant-skeleton-element {
      position: relative;
      width: 100px;
      height: 100px;
      padding: 0;
      margin-bottom: 12px;

      @media @s {
        width: calc(~'80 / 390 * 100vw');
        height: calc(~'80 / 390 * 100vw');
      }

      & > span {
        width: calc(~'80 / 390 * 100vw');
        height: calc(~'80 / 390 * 100vw');
      }
    }

    &.ant-image-img {
      background-color: #fff;
      width: 100px;
      height: 100px;
      object-fit: contain;
      object-position: center;

      @media @s {
        width: calc(~'80 / 390 * 100vw');
        height: calc(~'80 / 390 * 100vw');
      }
    }

    & .ant-skeleton-avatar {
      width: 100px !important;
      height: 100px !important;
      padding: 8px;
      position: relative;

      @media @s {
        width: calc(~'80 / 390 * 100vw') !important;
        height: calc(~'80 / 390 * 100vw') !important;
      }
    }
  }

  &__title {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 100%;
    color: @darkBlue;
    margin-bottom: 8px;
    text-align: center;

    @media @s {
      font-size: 12px;
      margin-bottom: 0;
    }
  }

  &__desc {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 200%;
    color: @black;

    @media @s {
      font-size: 12px;
    }
  }
}
