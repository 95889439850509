.d-creditsPage {
  position: relative;

  &.d-formAccount__wrapper {
    max-width: 500px;

    @media @mm {
      max-width: unset;
    }
  }

  &__titleRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__blueBlock {
    border-radius: 12px;
    background: rgba(168, 195, 236, 0.05);
    padding: 14px 30px;
    margin-bottom: 15px;
  }

  &__detailRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: @darkBlueOp7;
    font-size: 16px;
    line-height: 150%;

    & :last-child {
      font-weight: 600;
    }

    &:not(:last-of-type) {
      margin-bottom: 12px;
    }
  }

  &__subtitle {
    color: @darkBlue;
    font-size: 16px;
    line-height: 140%;
    margin-bottom: 15px;
    font-weight: 600;
  }

  &__borderedItem {
    padding-bottom: 8px;
    border-bottom: 1px solid @darkBlueOp7;

    &:not(:last-of-type) {
      margin-bottom: 14px;
    }
  }
}
