.d-forumItemPage {
  position: relative;
  margin-bottom: 120px;

  > .ant-image {
    width: 100%;
    height: 750px;
    margin-bottom: 30px;

    @media @m {
      height: 500px;
    }

    @media @s {
      height: auto;
    }
  }

  &__image.ant-image-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__wrapper {
    max-width: 1000px;
    margin: 0 auto;
  }

  &__title {
    color: @darkBlue;
    font-size: 32px;
    font-weight: 700;
    margin-bottom: 20px;
  }

  &__icons {
    display: flex;
    align-items: center;
    gap: 30px;
    color: @darkBlueOp7;
    font-size: 18px;
    margin-bottom: 50px;

    @media @mm {
      margin-bottom: 30px;
    }

    &-type {
      color: @darkBlue;
      padding: 6px 9px;
      border-radius: 12.535px;
      background: rgba(26, 68, 113, 0.1);
    }

    &-icon {
      display: flex;
      gap: 8px;
      align-items: center;

      &:not(:last-child) {
        svg path {
          stroke: @darkBlueOp7;
        }
      }

      &:last-child {
        svg path {
          fill: @darkBlueOp7;
        }
      }

      & .ant-btn {
        padding: 0;
        background: transparent;

        &:hover,
        &:focus {
          background: transparent;
        }
      }
    }
  }

  &__desc {
    color: @black;
    font-size: 16px;
    line-height: 170%;
    margin-bottom: 85px;

    @media @mm {
      margin-bottom: 50px;
    }
  }

  &__subtitle {
    color: @darkBlue;
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 20px;
  }

  &__inputComment {
    margin-bottom: 20px;
    .ant-input {
      border: 1px solid @borderColor;
      border-radius: 12px;
      font-size: 16px;
      padding: 20px 24px;

      &::placeholder {
        color: @mediumBlue;
      }

      &:hover,
      &:focus {
        box-shadow: none;
      }
    }
  }

  &__saveBtn.d-blueBtn {
    margin-left: auto;
    width: 230px;
    margin-bottom: 90px;

    @media @mm {
      margin-bottom: 40px;
    }
  }

  &__commentsBlock {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-select {
      .ant-select .ant-select-selector .ant-select-selection-item {
        padding-left: 60px;
      }
    }
  }
}
