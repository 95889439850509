.d-cardsPage {
  position: relative;

  &__titleRow {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .d-formAccount__title {
      margin-bottom: 30px;

      @media @s {
        font-size: 18px;
        margin-bottom: 0;
      }
    }
  }

  &__noCards {
    &::before {
      content: '';
      display: block;
      position: relative;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      width: 122px;
      height: 80px;
      margin: 0 auto 40px;
      background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='122' height='80' viewBox='0 0 122 80' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect y='0.373047' width='121.911' height='79.1667' rx='4' fill='url(%23paint0_linear_1925_25917)'/%3e%3crect x='14.6772' y='17.543' width='22.3327' height='16.1056' rx='3' fill='%23EFF2F6' stroke='white' stroke-width='2'/%3e%3cpath d='M32.5873 58.6484L13.6772 58.6484' stroke='white' stroke-width='2'/%3e%3cpath d='M57.8027 58.6484L38.8926 58.6484' stroke='white' stroke-width='2'/%3e%3cpath d='M83.019 58.6484L64.1089 58.6484' stroke='white' stroke-width='2'/%3e%3cpath d='M108.235 58.6484L89.3252 58.6484' stroke='white' stroke-width='2'/%3e%3cdefs%3e%3clinearGradient id='paint0_linear_1925_25917' x1='0' y1='39.9564' x2='121.911' y2='39.9564' gradientUnits='userSpaceOnUse'%3e%3cstop stop-color='%23DA22FF'/%3e%3cstop offset='1' stop-color='%239733EE'/%3e%3c/linearGradient%3e%3c/defs%3e%3c/svg%3e ");
    }

    &-title {
      color: @darkBlue;
      font-size: 24px;
      margin-bottom: 12px;
      font-weight: 600;
      text-align: center;
    }

    &-desc {
      color: @darkBlue;
      font-size: 16px;
      margin-bottom: 20px;
      text-align: center;
    }

    &-addBtn.d-blueBtn {
      width: 230px;
      margin: 0 auto;
    }
  }

  &__card {
    width: 360px;
    border-radius: 8px;
    padding: 25px;
    box-shadow: 0px 5px 20px 0px rgba(26, 60, 104, 0.15);

    display: flex;
    flex-flow: column;
    gap: 40px;

    @media @m {
      width: auto;
    }

    &:not(:last-of-type) {
      margin-bottom: 60px;
    }

    &-type {
      display: flex;
      align-items: center;
      &-text {
        gap: 14px;
        color: @darkBlue;
        font-size: 18px;
        font-weight: 500;
        margin-inline-start: 10px;
      }

      &-img.ant-image-img {
        width: 44px;
        object-fit: contain;
        // &-img {
        //   width: 100%;
        //   height: 100%;

        // }
      }
    }

    &-chip {
      width: 45px;
      height: 32px;

      .ant-image {
        width: 100%;
        height: 100%;

        &-img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }

    &-numbers {
      color: @darkBlue;
      font-size: 17px;
      line-height: 150%;

      display: flex;
      justify-content: space-between;
    }
  }
}
