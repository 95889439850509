.d-filterHome {
  margin-bottom: 100px;
  position: relative;

  @media @s {
    margin-bottom: 50px;
  }

  .d-wrapper {
    position: relative;

    &.no-padding {
      @media @xl {
        padding: 0 20px;
        overflow: hidden;

        .swiper {
          overflow: visible;
          width: 100%;
        }
      }

      @media (max-width: 1279px) {
        padding: 0;
      }
    }
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 45px;

    & .title {
      font-style: normal;
      font-weight: 700;
      font-size: 36px;
      line-height: calc(44 / 36);
      color: @darkBlue;

      @media @s {
        font-size: 24px;
      }
    }

    .link {
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 20px;
      color: @darkBlue;

      @media @s {
        font-size: 14px;
      }
    }
  }

  &__slider {
  }
}
