.d-checkoutPage {
  margin-block: 30px 80px;

  &__title {
    color: @darkBlue;
    font-size: 36px;
    font-weight: 700;
    margin: 0 0 15px;

    @media @s {
      // font-size: 24px;
      display: none;
    }
  }

  &__row {
    @media @mm {
      flex-direction: column;
    }
  }

  &__steps.ant-steps {
    width: max-content;
    margin-bottom: 25px;

    @media @s {
      width: 100%;
    }

    .ant-steps-item {
      min-width: 150px;

      @media @s {
        min-width: 100%;
      }
    }

    .ant-steps-item-active,
    .ant-steps-item-finish {
      @media @s {
        display: none;
      }
      > .ant-steps-item-container {
        > .ant-steps-item-tail {
          display: block;
          background: #00876e;

          @media @s {
            display: none !important;
          }
        }

        .ant-steps-item-icon {
          @media @s {
            position: relative;
            display: none;
          }
          .ant-steps-icon-dot {
            position: absolute;
            z-index: 10;
            width: 10px;
            height: 10px;
            left: -8px;
          }
          > .ant-steps-icon .ant-steps-icon-dot {
            background: #00876e;
          }
        }

        > .ant-steps-item-content > .ant-steps-item-title {
          color: @darkBlue;
          font-weight: 600;

          @media @s {
            font-size: 24px;
            font-weight: bold;
          }
        }
      }
    }

    .ant-steps-item-active {
      @media @s {
        display: block;
      }
    }

    .ant-steps-item-wait {
      @media @s {
        display: none;
      }

      .ant-steps-item-container {
        .ant-steps-item-icon {
          .ant-steps-icon-dot {
            position: absolute;
            z-index: 10;
            width: 10px;
            height: 10px;
            left: -8px;
          }
        }
      }
    }

    .ant-steps-item-container {
      .ant-steps-item-tail {
        margin: 0;
        background: #f4f9fe;

        &::after {
          background: transparent;
        }
      }

      .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
        background: #f4f9fe;
      }

      > .ant-steps-item-content > .ant-steps-item-title {
        color: @darkBlueOp7;
      }
    }

    .ant-steps-item:last-child:not(.ant-steps-item-active)
      > .ant-steps-item-container
      > .ant-steps-item-tail {
      display: block;
      background: #f4f9fe;
    }
  }

  &__item {
    border-radius: 6px;
    border: 1px solid #a8c3ec;
    padding: 20px;
    margin-bottom: 20px;
    cursor: pointer;
    display: flex;
    align-items: center;

    @media @s {
      padding: 15px;
    }

    &-cardWrapper {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }

    &-checkbox {
      width: 24px;
      min-width: 24px;
      height: 24px;
      background-image: none;
      margin-inline-end: 10px;
      border-radius: 13px;
      border: 1px solid @darkBlue;
      transition: all linear 0.3s;
      background-position: center;
      background-repeat: no-repeat;

      &.active {
        background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 3.5C7.30558 3.5 3.5 7.30558 3.5 12C3.5 16.6944 7.30558 20.5 12 20.5C16.6944 20.5 20.5 16.6944 20.5 12C20.5 7.30558 16.6944 3.5 12 3.5ZM2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12Z' fill='%23313F51'/%3E%3Cpath d='M12 2.5C17.2467 2.5 21.5 6.75329 21.5 12C21.5 17.2467 17.2467 21.5 12 21.5C6.75329 21.5 2.5 17.2467 2.5 12C2.5 6.75329 6.75329 2.5 12 2.5ZM14.8661 8.61612L10.75 12.7322L9.13388 11.1161C8.64573 10.628 7.85427 10.628 7.36612 11.1161C6.87796 11.6043 6.87796 12.3957 7.36612 12.8839L9.86612 15.3839C10.3543 15.872 11.1457 15.872 11.6339 15.3839L16.6339 10.3839C17.122 9.89573 17.122 9.10427 16.6339 8.61612C16.1457 8.12796 15.3543 8.12796 14.8661 8.61612Z' fill='url(%23paint0_linear_1925_24607)' stroke='%231A3C68'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_1925_24607' x1='2.01341' y1='11.9909' x2='22.0011' y2='11.9909' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0.0967699' stop-color='%23F5825C'/%3E%3Cstop offset='0.7901' stop-color='%23FFD167'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");
        border: none;
      }
    }

    &-title {
      color: @darkBlue;
      font-size: 18px;
      font-weight: 600;
    }

    &.select {
      background: #f4f9fe;
    }

    &:last-of-type {
      margin-bottom: 50px;
    }

    &-cardBlock {
      display: flex;
      // justify-content: space-between;
      align-items: center;

      &-numbers {
        color: rgba(18, 18, 29, 0.35);
        font-size: 16px;
      }

      .ant-image {
        width: 32px;

        &-img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }

  &__continueBtn.d-blueBtn {
    height: 70px;
  }

  &__yourCart {
    &-title {
      margin: 0;
    }

    &-editBtn.d-formAccount__btn {
      width: 150px;
    }

    &-totalSummary.d-cart__summary-detail.total {
      margin-bottom: 0;
    }
  }
}
