.d-menu {
  &__wrapper.ant-menu {
    border-radius: 12px;
    background: #fff;
    width: 100%;
    max-width: 360px;
    padding: 30px;

    &.ant-menu-root {
      border: none;
      border: 1px solid rgba(26, 60, 104, 0.3);
    }

    .ant-menu-item {
      padding: 0 8px !important;
      margin: 0;
      height: 44px;
      line-height: 44px;
      width: 100%;

      display: flex;
      flex-flow: row-reverse;
      justify-content: space-between;
      align-items: center;

      color: @darkBlue;
      .ant-menu-title-content {
        margin: 0;
      }

      &:not(:last-of-type) {
        margin-bottom: 10px;
      }

      &:last-of-type {
        color: @red;
        margin-top: 30px;
        height: 28px;
      }

      &-selected {
        background: rgba(255, 209, 103, 0.4);
        font-weight: 500;
      }

      &:not(.ant-menu-item-selected):hover {
        background: rgba(255, 209, 103, 0.2);

        &:last-of-type {
          color: @red;
          background: transparent;
        }
      }
    }
  }

  &__mobile {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    &-curent {
      max-width: 500px;
      width: 100%;
      padding: 10px 50px;
      border-radius: 50px;
      // border: 1px solid transparent;
      background: linear-gradient(90deg, #f5825c 9.68%, #ffd167 79.01%);
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;

      .title {
        color: @white;
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
      .curent {
        // padding: 0 50px;
        flex: 1;
        display: flex;
        justify-content: space-between;

        &-icons {
          display: flex;
          align-items: center;
        }

        &-arrow {
          margin-inline-start: 20px;
          transition: all 0.3s linear;

          &.active {
            transform: rotate(180deg);
          }
        }
      }
    }

    &-items {
      max-width: 500px;
      width: 100%;
      max-height: 0;
      // height: 300;
      overflow: hidden;
      transition: all linear 0.3s;

      &.active {
        // overflow: visible;
        transition: all linear 0.3s;
        max-height: 370px;
      }
    }

    &-item {
      padding: 10px 50px;
      flex: 1;
      display: flex;
      justify-content: space-between;
      max-width: 500px;
      width: 100%;
      border-radius: 50px;
      border: 1px solid @borderColor;
      background-color: transparent;
      margin-bottom: 10px;

      .title {
        color: @darkBlue;
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;

        &.signOut {
          color: @red;
        }
      }
    }
  }
}
