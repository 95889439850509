.d-articleItem {
  position: relative;
  display: block;
  overflow: hidden;
  border-radius: 18px;
  border: 1px solid @borderColor;
  height: 310px;

  &__image {
    position: relative;
    height: 180px;

    .ant-image {
      width: 100%;
      height: 180px;

      &-img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &-icons {
      position: absolute;
      top: 0;
      left: 0;
      padding: 20px 30px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      gap: 30px;

      color: @white;
      font-size: 18px;

      @media @mm {
        align-items: flex-start;
        padding: 16px;
      }

      &-type {
        margin-right: auto;
        padding: 6px;
        border-radius: 12px;
        background: rgba(94, 167, 255, 0.1);

        @media @s {
          font-size: 14px;
        }
      }

      &-icon {
        display: flex;
        gap: 8px;
        align-items: center;
        margin-top: 8px;
      }
    }
  }

  &__info {
    padding: 20px 30px;

    &-title {
      color: @darkBlue;
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 10px;

      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    &-desc {
      color: rgba(18, 18, 29, 0.7);
      font-size: 16px;

      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
}
