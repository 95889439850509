.d-breadCrumbs {
  margin-bottom: 20px;

  .ant-breadcrumb-link a,
  .ant-breadcrumb-link {
    color: rgba(26, 60, 104, 0.5);

    a:hover {
      color: @darkBlue;
      background: transparent;
    }

    &:not(a) {
      color: @darkBlue;
    }
  }

  &-currentPage {
    color: @darkBlue;
  }

  .ant-breadcrumb-separator {
    color: @darkBlue;
    font-weight: 900;
  }
}
