.d-shippingPage {
  &__titleBlock {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;

    @media @s {
      align-items: center;
    }
  }

  &__newAddressBtn.ant-btn {
    padding-inline: 18px;
    font-weight: 500;
    font-size: 16px;
  }

  &__addressItem {
    &-titleWithIcon {
      display: flex;
      align-items: center;
      gap: 8px;
    }

    &-name {
      font-weight: 500;
      margin-bottom: 6px;
    }
    &-phone {
      margin-bottom: 12px;
    }
  }

  &__modal {
    &-title {
      margin-bottom: 55px;

      @media @s {
        margin-bottom: 20px;
      }
    }

    &-saveBtn.ant-btn {
      max-width: 330px;
      margin: 20px auto 0;
    }
  }

  .d-formAccount__title {
    @media @s {
      font-size: 18px;
      margin-bottom: 0;
    }
  }
}
