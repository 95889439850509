.d-otpInput {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;

  input {
    width: 70px;
    line-height: 120%;
    height: 60px;
    font-size: 24px;
    border: 1px solid @borderColor;
    background: #fafafa;
    border-radius: 16px;
    text-align: center;
    color: @black;

    &:hover {
      border-color: #eaac41;
    }

    &:focus {
      border-color: #eaac41;
      background: rgba(234, 172, 65, 0.08);
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
  }

  input[type='number'],
  input[type='number']:hover,
  input[type='number']:focus {
    appearance: none;
    -moz-appearance: textfield;
  }
}
