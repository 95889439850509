.d-orderPage {
  &__orderItem {
    &-colInfo {
      span {
        display: block;
      }
    }
  }

  &__modal {
    &-title {
      margin-bottom: 35px;
    }

    &-item {
      display: flex;
      gap: 18px;

      margin-bottom: 20px;

      .ant-image {
        min-width: 80px;
        max-width: 80px;
        height: 74px;
        border: 1px solid @borderColor;
        border-radius: 18px;

        &-img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          padding: 4px 8px;
        }
      }

      &-title {
        display: block;
        color: @darkBlue;
        font-size: 18px;
        line-height: 140%;
        font-weight: 500;
        margin-bottom: 8px;
      }

      &-details {
        color: @black;
        display: flex;
        flex-flow: row wrap;
        gap: 10px 40px;
      }
    }

    &-info.ant-col {
      color: @darkBlue;
      font-size: 18px;
      line-height: 150%;
    }

    &-bundleItem {
      border-bottom: 0.1px solid @darkBlue;
      margin-bottom: 10px;
      &--title {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        margin-bottom: 10px;

        .d-orderPage__modal-item-title {
          margin-bottom: 0;
          margin-inline-end: 10px;
        }
      }
    }
  }
}
