.d-cart {
  position: relative;
  margin-top: 20px;

  .d-wrapper > .ant-row {
    justify-content: space-between;
    margin-bottom: 100px;
  }

  &__title {
    color: @darkBlue;
    font-size: 36px;
    font-weight: 600;
    margin-bottom: 8px;
  }

  &__desc {
    color: @darkBlueOp7;
    font-size: 16px;
    margin-bottom: 40px;
  }

  &__item {
    position: relative;
    display: flex;
    gap: 18px;

    @media @s {
      // flex-direction: column;
      padding-bottom: 12px;
      gap: 10px;
      border-bottom: 1px solid @borderColor;
    }

    &:not(:last-of-type) {
      margin-bottom: 30px;
    }

    .ant-image {
      border-radius: 18px;
      border: 1px solid @borderColor;
      min-width: 230px;
      max-width: 230px;
      height: 230px;

      @media @s {
        width: 80px;
        min-width: 80px;

        height: 80px;
      }
    }

    &-img.ant-image-img {
      padding: 15px;
      width: 100%;
      height: 100%;
      object-fit: cover;

      @media @s {
        padding: 0;
      }
    }

    &-info {
      @media @s {
        width: 100%;
      }
    }

    &-title {
      color: @darkBlue;
      font-size: 22px;
      line-height: 150%;
      font-weight: 500;
      margin: 0 35px 10px 0;

      @media @s {
        font-size: 14px;
      }
    }

    &-size {
      color: rgba(18, 18, 29, 0.7);
      font-size: 16px;
      line-height: 150%;
      margin-bottom: 10px;
    }

    &-price {
      color: @darkBlue;
      font-size: 24px;
      font-weight: 600;
      line-height: 150%;

      @media @s {
        font-size: 14px;
      }
    }

    &-close.ant-btn {
      position: absolute;
      top: 0;
      right: 0;

      background: transparent;
      box-shadow: none;
      padding: 0;
      border: none;

      svg g {
        transition: 0.2s all linear;
      }

      &:hover,
      &:focus {
        background: transparent;

        svg g {
          opacity: 1;
        }
      }
    }

    &-btnCount {
      @media @s {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }

    &-counter {
      position: relative;
      border-radius: 26px;
      background: rgba(18, 18, 29, 0.05);
      width: max-content;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 20px;
      height: 42px;
      padding: 0 24px;
      margin-bottom: 10px;

      @media @s {
        margin-bottom: 0;
        padding-inline: 15px;
        gap: 12px;
      }

      .ant-input-number,
      .ant-btn {
        font-size: 18px;
        line-height: 120%;
        color: @black;
        font-weight: 600;

        border: none;
        box-shadow: none;
        background: transparent;
        padding: 0;

        &:hover,
        &:focus {
          background: transparent;
          color: @black;
        }

        @media @s {
          font-size: 14px;
        }
      }

      .ant-input-number {
        .ant-input-number-input {
          text-align: center;
          padding: 0;
        }

        @media @s {
          width: 45px;
        }
      }
    }
  }

  .ant-btn > div {
    display: none;
  }

  &__summary {
    position: sticky;
    top: 20px;
    box-shadow: 0px 30px 60px 0px rgba(49, 63, 81, 0.1);
    border-radius: 24px;
    padding: 40px;

    @media @m {
      margin-top: 60px;
    }

    @media @s {
      margin-top: 20px;
      padding: 15px;
      box-shadow: none;
    }

    &-title {
      color: @darkBlue;
      font-size: 22px;
      font-weight: 600;
      margin-bottom: 40px;
      text-align: center;

      @media @s {
        // font-size: 14px;
        margin-bottom: 20px;
      }
    }

    &-detail {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;

      color: @black;
      font-size: 18px;
      line-height: 150%;

      @media @s {
        font-size: 14px;
      }

      &.total {
        color: @darkBlue;
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 30px;
      }

      &.credit {
        color: @darkBlue;
        font-size: 16px;
        font-weight: 700;

        @media @s {
          font-size: 14px;
          align-items: center;
        }
      }
    }

    &-useCreditBtn.ant-btn {
      color: @darkBlue;
      font-size: 16px;
      font-weight: 500;
      border-radius: 30px;
      background: rgba(18, 18, 29, 0.05);
      box-shadow: none;
      width: 150px;
      height: 50px;

      @media @s {
        font-size: 12px;
        width: 100px;
        height: 30px;
      }

      &:hover,
      &:focus {
        background: rgba(18, 18, 29, 0.05);
        color: @darkBlue;
      }
    }

    &-continueBtn.ant-btn {
      color: @white;
      font-size: 18px;
      font-weight: 500;
      height: 70px;
      width: 100%;
      border-radius: 50px;
      box-shadow: none;
      border: none;
      background: linear-gradient(90deg, @orange 9.68%, @yellow 79.01%);
    }
  }

  &__releatedProducts {
  }

  &__modal {
    &-title {
      color: @darkBlue;
      font-size: 18px;
      text-align: center;
      display: block;
      font-weight: 600;
      margin-bottom: 20px;
    }

    &-btns {
      display: flex;
      gap: 20px;

      @media @s {
        gap: 10px;
      }

      &-no.d-blueBtn {
        background: transparent;
        color: @lightBlue;
        border: 1px solid @lightBlue;

        &:hover,
        &:focus {
          background: transparent;
          color: @lightBlue;
          border: 1px solid @lightBlue;
        }
      }
    }
  }
}
