.d-termsPage {
  position: relative;
  margin-bottom: 140px;

  p {
    color: @black;
    font-size: 16px;
    line-height: 170%;
    margin-bottom: 40px;

    strong {
      color: @darkBlue;
      font-size: 20px;
    }
  }
}
