.d-accountPage {
  padding-top: 20px;
  margin-bottom: 70px;
  position: relative;

  &__title {
    color: @darkBlue;
    font-size: 36px;
    font-weight: 600;
    margin: 0 0 15px;
  }

  &__row {
    display: flex;
    align-items: flex-start;
    gap: 30px;

    @media @mm {
      flex-direction: column;
    }
  }

  &__form {
    &-subtitle {
      color: @darkBlue;
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 20px;
    }

    &-block {
      margin-bottom: 50px;

      @media @s {
        margin-bottom: 20px;
      }
    }

    &-connections,
    &-changePass {
      width: calc(~'50% - 15px');

      @media @m {
        width: 100%;
      }
    }

    &-connections {
      &-item {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &:not(:last-of-type) {
          margin-bottom: 20px;
        }

        > div {
          display: flex;
          gap: 12px;
          align-items: center;

          color: @darkBlue;
          font-size: 16px;
          font-weight: 600;
        }

        .ant-btn {
          height: 36px;
          margin: 0;
          padding: 0 12px;
          border: none;
          box-shadow: none;

          border-radius: 20px;
          background: rgba(255, 35, 67, 0.15);
          color: @red;
          font-size: 12px;
          font-weight: 500;

          &:hover {
            background: rgba(255, 35, 67, 0.15);
            color: @red;
          }
        }
      }
    }

    &-saveBtn.d-blueBtn {
      width: 230px;

      @media @s {
        width: 100%;
      }
    }
  }

  &__input.d-input,
  &__select.d-select {
    @media @m {
      margin-bottom: 0;
    }
  }
}
