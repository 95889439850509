.d-footer {
  position: relative;
  // border-top: 1px solid;
  padding-top: 40px;
  // margin-top: 15px;
  background: #fafafa;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 3px;
    background: linear-gradient(90deg, @darkBlue 21.01%, @lightBlue 90.31%);
  }

  &__infoblock {
    display: flex;
    justify-content: space-between;
    gap: 10px;

    padding-bottom: 30px;
    border-bottom: 1px solid @borderColor;
    margin-bottom: 20px;
  }

  &__items {
    margin-bottom: 120px;

    @media @mm {
      margin-bottom: 60px;
    }

    @media @s {
      margin-bottom: 20px;
    }
  }

  &__copyright {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 15px;
    padding-top: 15px;
    font-size: 14px;
    color: rgba(18, 18, 29, 0.7);
    border-top: 1px solid @black;

    @media @s {
      flex-direction: column-reverse;
    }

    &-cards {
      display: flex;
      // justify-content: center;
      gap: 24px;
      margin: 15px 0;
    }
  }

  &__logo {
    // flex: 0 0 700px;
    display: flex;
    flex-direction: column;

    @media @xxl {
      // flex: 1 0;
    }

    svg {
      margin-bottom: 30px;
    }

    a {
      text-decoration: none;
      font-size: 20px;
      font-weight: 600;
      line-height: 200%;
      color: @darkBlue;
      width: max-content;

      @media @s {
        width: 100%;
      }
    }
  }

  &__catalog {
    // flex: 0 0 390px;
    display: flex;
    flex-direction: column;

    @media @xxl {
      // flex: 0 0 (350 / 1440 * 100vw);
    }

    &-title {
      margin-bottom: 20px;
      font-weight: 600;
      color: @darkBlue;
    }

    &-item {
      &:not(:last-of-type) {
        margin-bottom: 8px;
      }

      color: rgba(18, 18, 29, 0.7);
      font-size: 14px;
      line-height: 200%;
      text-decoration: none;
      width: max-content;

      @media @s {
        width: 100%;
      }
    }
  }

  &__info {
    // flex: 1 0 310px;
    display: flex;
    flex-direction: column;

    @media @xxl {
      // flex: 0 0 (200 / 1440 * 100vw);
    }
  }
}
