.d-home__faq {
  position: relative;
  margin-bottom: 65px;

  &-wrapper {
    .title {
      font-style: normal;
      font-weight: 700;
      font-size: 56px;
      line-height: 62px;
      color: @darkBlue;
      margin-bottom: 60px;

      @media @s {
        margin-bottom: 0;
        font-size: 24px;
      }
    }

    .ant-row > .ant-col {
      @media @sm {
        width: 100%;
      }
    }
  }

  &-item {
    border: none;
    background: transparent;
    border-radius: 0;
    .ant-collapse-item {
      border: none;
      border-bottom: 1px solid @borderColor;
      border-radius: 0 !important;

      .collapse-icon {
        transition: all linear 0.3s;
      }

      .ant-collapse-header {
        background: transparent;
        border: none;
        align-items: center;
        padding: 0 0 16px;
      }
    }

    .accordion-title {
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 120%;
      color: @black;
    }

    .ant-collapse-item.ant-collapse-item-active {
      border-radius: 0;

      .collapse-icon {
        transition: all linear 0.3s;
        transform: rotate(180deg);
      }
    }

    .ant-collapse-content.ant-collapse-content-active {
      border-color: @borderColor;
      color: @black;
      .ant-collapse-content-box {
        // padding-left: 0;
      }
    }
    .ant-collapse-content.ant-collapse-content-hidden {
      .ant-collapse-content-box {
        // padding-left: 0;
      }
    }
  }
}
