.d-aboutPage {
  position: relative;
  padding-bottom: 120px;
  height: 100%;

  .d-titleWithBg {
    margin-bottom: 80px;
    @media @s {
      margin-bottom: 25px;
    }
  }

  &__text {
    position: relative;
    text-align: center;
    font-size: 16px;
    color: @black;

    max-width: 1030px;
    margin: 0 auto;

    @media @s {
      text-align: initial;
    }
  }

  &__logo {
    position: relative;
    width: max-content;
    margin: 0px auto 95px;

    @media @mm {
      margin-bottom: 60px;
    }

    @media @s {
      margin: 0px auto 10px;
    }

    svg {
      width: 825px;
      height: 162px;

      @media @mm {
        width: 100%;
        height: (162 / 768 * 100vw);
      }

      @media @s {
        // width: 320px;
        height: (63 / 320 * 100vw);
      }
    }
  }

  &__decorLines {
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      width: 677px;
      height: 1321px;
      background-repeat: no-repeat;
      background-size: cover;
      background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='677' height='1321' viewBox='0 0 677 1321' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cg opacity='0.2'%3e%3cpath d='M262.18 1413.33C316.701 1377.08 433.861 1267.86 466.34 1121C506.938 937.423 222.373 1067.24 348.497 849.49C474.621 631.743 646.845 689.102 586.584 415.162C526.323 141.222 862.627 266.875 896.739 76.5379' stroke='%23161962'/%3e%3cpath d='M298.867 1413.96C353.387 1377.71 470.548 1268.5 503.026 1121.64C543.624 938.058 259.06 1067.87 385.183 850.124C511.307 632.378 683.531 689.736 623.27 415.797C563.009 141.857 899.314 267.51 933.425 77.1727' stroke='%2330E986'/%3e%3cpath d='M335.555 1414.6C390.076 1378.35 507.236 1269.13 539.715 1122.27C580.313 938.693 295.748 1068.51 421.872 850.759C547.996 633.013 720.22 690.371 659.959 416.431C599.698 142.492 936.002 268.144 970.114 77.8074' stroke='%23E9BE2F'/%3e%3cpath d='M372.242 1415.23C426.762 1378.98 543.923 1269.77 576.401 1122.91C616.999 939.328 332.435 1069.14 458.558 851.394C584.682 633.648 756.906 691.006 696.645 417.066C636.384 143.126 972.689 268.779 1006.8 78.4422' stroke='%23FE01CF'/%3e%3c/g%3e%3c/svg%3e ");

      @media @mm {
        display: none;
      }
    }

    &::before {
      top: -250px;
      right: initial;
      left: 0;
      width: 833px;
      height: 1520px;
      background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='833' height='1523' viewBox='0 0 833 1523' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cg opacity='0.2'%3e%3cpath d='M24.9554 1663.52C-10.4314 1608.44 -73.905 1461.38 -44.7056 1313.84C-8.2064 1129.4 200.327 1362.51 172.206 1112.45C144.086 862.393 -36.679 845.801 128.467 619.083C293.614 392.365 -64.8461 372.468 -19.6928 184.445' stroke='%23161962'/%3e%3cpath d='M-8.90153 1649.38C-44.2883 1594.3 -107.762 1447.24 -78.5626 1299.69C-42.0633 1115.26 166.47 1348.37 138.349 1098.31C110.229 848.25 -70.536 831.659 94.6104 604.941C259.757 378.223 -98.703 358.326 -53.5498 170.302' stroke='%2330E986'/%3e%3cpath d='M-42.7594 1635.24C-78.1462 1580.15 -141.62 1433.1 -112.42 1285.55C-75.9212 1101.11 132.612 1334.22 104.492 1084.17C76.3709 834.105 -104.394 817.514 60.7525 590.796C225.899 364.078 -132.561 344.181 -87.4077 156.158' stroke='%23E9BE2F'/%3e%3cpath d='M-76.6164 1621.09C-112.003 1566.01 -175.477 1418.95 -146.277 1271.4C-109.778 1086.97 98.7554 1320.08 70.6346 1070.02C42.5139 819.961 -138.251 803.37 26.8956 576.652C192.042 349.934 -166.418 330.037 -121.265 142.013' stroke='%23FE01CF'/%3e%3c/g%3e%3c/svg%3e ");
    }
  }
}
