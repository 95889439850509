.d-cat {
  padding-top: 20px;
  margin-bottom: 80px;

  &__header {
    margin-bottom: 30px;
    display: flex;
    gap: 16px;

    @media @sm {
      flex-flow: row wrap;
    }

    @media @s {
      flex-direction: column;
      margin-bottom: 0;
    }

    .ant-select-selector {
      background: transparent !important;
    }

    .select-filter {
      position: relative;
      z-index: 3;
    }

    .d-selectFilter-title {
      z-index: 2;
    }
  }

  &__wrapper {
    .d-product {
      cursor: pointer;
      border: none;
      padding-top: 0;
    }
  }

  .d-wrapper {
    overflow-x: hidden;
  }

  &__child {
    // display: flex;
    // justify-content: space-evenly;
    // gap: 30px;
    margin-bottom: 40px;

    @media @s {
      margin: 0 -20px 20px;
    }

    .swiper {
      overflow: visible;
      width: 100%;
    }
  }

  &__clearFilterBtn.ant-btn {
    border: none;
    box-shadow: none;
    color: @darkBlue;
    font-weight: 600;
    font-size: 16px;

    &:hover,
    &:focus {
      color: @darkBlue;
    }
  }

  .ant-btn > div {
    display: none;
  }

  &__mobFilterBtn.ant-btn {
    padding: 0;
    border: none;

    &:hover,
    &:focus {
      background: transparent;
    }
  }
}
