.d-notificationPage {
  position: relative;

  &__item {
    position: relative;
    padding-bottom: 20px;
    border-bottom: 1px solid #e3e3e3;

    &:not(:last-of-type) {
      margin-bottom: 20px;
    }

    &-headRow {
      display: flex;
      justify-content: space-between;
      gap: 20px;
      margin-bottom: 20px;
    }

    &-title {
      color: @darkBlue;
      font-size: 18px;
      font-weight: 600;
    }

    &-date {
      color: @black;
    }

    &-desc {
      color: @darkBlueOp7;
    }

    &-popup {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      cursor: pointer;
    }
  }

  &__modal.d-modal {
    .d-modal__desc {
      margin-bottom: 0;
    }
  }
}
