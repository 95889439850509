.d-product {
  position: relative;

  &__carousel-wrapper {
    cursor: pointer;
    display: flex;
    flex-flow: column;
  }

  > .d-wrapper {
    padding-top: 20px;
    margin-bottom: 90px;
  }

  &__img.ant-image-img {
    border: 1px solid @borderColor;
    background: @white;
    border-radius: 19px;
    width: 100%;
    height: 320px;
    object-fit: cover;
    margin-bottom: 20px;
    padding: 20px;

    @media @mm {
      height: 240px;
    }

    @media @s {
      padding: 0;
      height: calc(~'160 / 390 * 100vw');
    }
  }

  &__title {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 120%;
    color: @darkBlue;
    margin-bottom: 10px;
    word-break: break-word;

    @media @s {
      font-size: 14px;
    }
  }

  &__desc {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 115%;
    color: @black;
    margin-bottom: 10px;
  }

  &__favorite-btn.ant-btn {
    position: absolute;
    right: 15px;
    top: 15px;
    transition: 0.2s all linear;

    padding: 0;
    margin: 0;
    border: none;

    @media @s {
      right: 5px;
      top: 5px;
      .ant-btn-icon {
        span {
          svg {
            width: 30px;
            height: 30px;
          }
        }
      }
    }

    &:hover {
      background: transparent;

      svg path {
        stroke-opacity: 1;
      }
    }

    // &.active {
    //   svg path {
    //     fill: @red;
    //     stroke: @red;
    //   }
    // }
  }

  &__swiper {
    position: relative;
    width: 100%;

    &-wrapper {
      height: 500px;
      max-height: 620px;
      border-radius: 18px;

      @media @s {
        height: calc(~'100vw - 30px');
      }

      .swiper-wrapper {
        align-items: center;
      }

      .swiper-pagination {
        position: absolute;
        bottom: 10px;
        z-index: 2;
        width: 100%;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;

        & > .swiper-pagination-bullet {
          display: block;
          cursor: pointer;
          min-width: 6px;
          height: 2px;
          background-color: @borderColor;
          opacity: 1;

          &:not(:last-child) {
            margin-inline-end: 4px;
          }

          &-active {
            width: 40px;
            background-color: @darkBlue;
          }
        }
      }
    }

    &-img-root {
      height: 100%;
      display: flex;
      align-items: center;

      .ant-image-img {
        padding: 15px;
        width: 100%;
        height: 100%;
        object-fit: cover;
        background: #f9f9fa;

        @media @s {
          padding: 0;
        }
      }
    }

    &-img-fullSize {
      margin-top: 40px;
      border-radius: 18px;
      background: #f9f9fa;
      height: 500px;
      padding: 15px;

      .ant-image {
        width: 100%;
        height: 100%;

        &-img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }

  &__info {
    margin-bottom: 35px;

    &-header {
      .d-product__favorite-btn {
        top: 5px;
        right: 40px;

        @media @s {
          right: 0;
          top: 0;
        }
      }

      h1 {
        text-align: start;
        color: @darkBlue;
        font-size: 32px;
        line-height: 120%;
        font-weight: 600;
        margin: 0 45px 24px 0;

        @media @s {
          margin-right: 30px;
          font-size: 24px;
        }
      }
    }

    &-ref {
      color: @darkBlue;
      font-size: 16px;

      .title {
        margin-inline-end: 40px;
        color: @mediumBlue;
      }
    }
  }

  &__table {
    background: @lightOrange;
    padding: 30px;
    border-radius: 18px;
    margin-bottom: 30px;

    @media @s {
      padding: 0;
    }

    .ant-segmented.d-product__tabs {
      width: 100%;
      padding: 0;
      margin-bottom: 20px;
      background: transparent;

      .ant-segmented-group {
        background: transparent;
        box-shadow: none;
        border-radius: 50px;
        padding: 0;
        align-items: center;
        justify-content: center;
        gap: 60px;
        height: 45px;
        width: 90%;
        margin: 0 auto;
        max-width: 100%;

        @media @mm {
          gap: 20px;
        }

        @media @s {
          gap: 0;
        }

        .ant-segmented-item {
          min-width: 110px;
          color: @mediumBlue;
          font-size: 16px;
          font-weight: 600;
          text-transform: uppercase;
          border-radius: 50px;
          padding: 5px 40px;
          border: none;
          box-shadow: none;

          @media @mm {
            padding-inline: 10px;
          }

          @media @s {
            min-width: 60px;
            font-size: 14px;
            padding: 5px 0;
          }

          &.ant-segmented-item-selected {
            color: @white;
            background: linear-gradient(90deg, @orange 9.74%, @yellow 79.03%);
          }
        }
      }

      .ant-segmented-thumb {
        background: linear-gradient(90deg, @orange 9.74%, @yellow 79.03%);
        border-radius: 50px;
      }
    }

    &-inner {
      // header-rows
      > .ant-row:first-of-type {
        color: rgba(26, 60, 104, 0.5);
        font-size: 18px;
        text-align: center;
        line-height: 50px;

        @media @s {
          line-height: 1.4;
          display: flex;
          justify-content: center;
          align-items: stretch;

          > * {
            font-size: 12px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }

        border-bottom: 1px solid #e1cfb3;

        & :not(:last-child) {
          // border-right: 1px solid #e1cfb3;
          &::after {
            content: '';
            position: absolute;
            display: block;
            width: 1px;
            top: 0;
            bottom: 0;
            right: 0;
            background-color: #e1cfb3;
          }
        }
      }

      // table body
      > .ant-row + .ant-row {
        color: rgba(26, 60, 104, 0.5);
        font-size: 18px;
        text-align: center;
        line-height: 50px;

        .ant-col:not(:last-of-type) {
          // border-right: 1px solid #e1cfb3;
          &::after {
            content: '';
            position: absolute;
            display: block;
            width: 1px;
            top: 0;
            bottom: 0;
            right: 0;
            background-color: #e1cfb3;
          }
        }
        //table cells
        > .ant-col:not(:first-of-type) .ant-row {
          color: @darkBlue;

          .ant-col {
            padding-block: 5px;

            @media @s {
              font-size: 12px;
            }

            > div:not(.d-product__addToCartCell) > span {
              // background: red;
              color: rgba(26, 60, 104, 0.5);
            }
          }

          &:not(:last-child) {
            border-bottom: 1px solid #e1cfb3;
          }
        }

        // header-cols
        > .ant-col:first-of-type div {
          padding-block: 5px;

          @media @s {
            font-size: 12px;
          }

          &:not(:last-child) {
            border-bottom: 1px solid #e1cfb3;
          }
        }
      }
    }
  }

  &__addToCartCell {
    position: relative;
    display: flex;
    flex-flow: column;
    justify-content: center;
    height: 50px;

    .ant-btn > div {
      display: none;
    }

    &-btns {
      position: relative;
      background: @white;
      border-radius: 20px;
      border: 1px solid #000;
      height: 32px;
      margin: 0 auto;
      // padding: 0px 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: 125px;

      // @media @mm {
      //   max-width: 70px;
      // }

      @media @s {
        width: 30px;
        height: 30px;
        border-radius: 16px;

        &.active {
          width: 100%;
          max-width: 70px;
        }
      }

      .ant-btn {
        background: transparent;
        border: none;
        padding: 0;
        height: 16px;
        font-size: 20px;
        color: @darkBlue;
        box-shadow: none;
        padding: 0 15px;

        display: flex;
        justify-content: center;
        align-items: center;

        @media @mm {
          padding: 0 8px;
        }

        @media @s {
          padding: 0 5px;
        }

        span {
          line-height: 16px;
        }
      }

      .ant-input-number {
        border-radius: 0;
        line-height: 16px;
        border: none;

        &-focused {
          box-shadow: none;
        }

        &-input {
          padding: 0;
          height: 16px;
          text-align: center;
          color: @darkBlue;
          font-size: 16px;
          font-weight: 600;

          @media @s {
            height: 20px;
          }
        }
      }
    }

    &-total {
      color: @darkBlue;
      font-weight: 600;
      font-size: 12px;
      line-height: 110%;
      margin-top: 3px;
    }
  }

  &__price {
    color: @darkBlue;
    font-size: 22px;
    margin-bottom: 20px;
    text-align: center;

    span {
      font-weight: 600;
    }

    &.in-list {
      text-align: start;
      margin-bottom: 0;
      font-weight: 500;
      font-size: 18px;
    }
  }

  &__selectBtn.d-blueBtn {
    position: relative;
    margin-bottom: 20px;
    height: 70px;

    span {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      vertical-align: middle;
    }
  }

  &__icons {
    display: flex;
    justify-content: center;
    gap: 30px;
    margin-bottom: 50px;

    @media @s {
      flex-direction: column;
    }

    &-item {
      color: @mediumBlue;
      display: flex;
      gap: 6px;
      align-items: center;
    }
  }

  .ant-collapse {
    margin-top: 30px;
    border-top: 1px solid #e3e3e3;
    border-radius: 0;

    &-item > .ant-collapse-header {
      padding-left: 0;
      align-items: center;
    }

    &-header-text {
      color: @darkBlue;
      font-size: 20px;
      font-weight: 700;
    }

    &-arrow {
      transition: 0.2s all linear;
    }

    .ant-collapse-content-box {
      padding: 0;
    }
  }

  &__other {
    position: relative;
    margin: 80px 0 0px;

    &-title {
      font-size: 36px;
      line-height: 120%;
      color: @darkBlue;
      margin: 0 0 45px;
      font-weight: 600;
      text-align: start;
    }

    &-swiper-wrapper.swiper {
      padding-inline: 5px;
    }

    .swiper-button-next.d-swiper__next {
      @media @xl {
        right: -10px;
      }
    }

    .swiper-button-prev.d-swiper__prev {
      @media @xl {
        left: -10px;
      }
    }
  }

  &__loginToView {
    border-radius: 12px;
    background: rgba(185, 75, 75, 0.1);
    padding: 10px;
    width: max-content;
    margin: 0 auto 30px;
    color: #b94b4b;
    display: flex;
    align-items: center;
    gap: 10px;
  }

  &__bundle {
    &-row.ant-row {
      margin-top: 100px;

      @media @mm {
        margin-top: 60px;
      }
    }

    &-btn.d-blueBtn {
      margin: 40px auto 0;
      width: 550px;
      height: 70px;

      @media @s {
        width: 100%;
      }
    }
  }

  //
  &__addToCartCell {
    &.one {
      .d-product__addToCartCell-btns {
        border: 1px solid @black !important;
        padding: 10px;

        .ant-input-number {
          border: none !important;
          padding-block: 0 !important;
        }

        .ant-btn {
          padding: 0 10px;
        }

        &.one {
          max-width: 120px;
          min-height: 32px;
        }
      }

      .d-product__addToCartCell-total {
        margin-top: 10px;
      }

      @media @s {
        .d-product__addToCartCell-btns {
          padding: 0;
        }
      }
    }
  }
}

.ant-image-preview-img {
  background-color: white;
}

.ant-image .ant-image-mask {
  background: none;
}

.d-product__table {
  .d-product__table-inner {
    & > .ant-row {
      justify-content: center;
    }
  }
}

// New CSS Start
.ant-input-number-input-wrap {
    padding: 0 !important;
}
// New CSS End