.d-home {
  position: relative;
  overflow: hidden;

  &__categories {
    padding-bottom: 20px;
    border-bottom: 1px solid @borderColor;

    @media @s {
      border: none;
    }

    & > .d-wrapper {
      // display: flex;
      // justify-content: space-between;
      // gap: 20px;

      overflow: hidden;

      @media (max-width: 639px) {
        padding: 0;
      }

      .swiper {
        width: 100%;
        overflow: visible;
        margin-left: 0;
        margin-right: 0;
      }
    }
  }

  .d-product {
    cursor: pointer;
    border: none;
    padding-top: 0;
  }

  &__banner {
    position: relative;
    width: 100%;
    // max-width: 99vw;
    // overflow: hidden;
    // margin: 0 auto;
    // max-height: 665px;
    // height: 665px;
    margin-bottom: 80px;

    @media @s {
      margin-bottom: 20px;
    }

    &-slider-wrapper {
      position: relative;

      .swiper-pagination {
        position: absolute;
        bottom: 5%;
        z-index: 2;
        width: 100%;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;

        & > .swiper-pagination-bullet {
          display: block;
          cursor: pointer;
          min-width: 16px;
          height: 16px;
          border-radius: 50%;
          background-color: @borderColor;
          opacity: 1;

          &:not(:last-child) {
            margin-inline-end: 24px;
          }

          &-active {
            background-color: @darkBlue;
          }
        }
      }

      .swiper-slide {
        height: calc(~'664 / 1920 * 100vw');
        @media @s {
          height: 100%;
        }
      }
    }

    &-img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      cursor: pointer;
      // max-height: 665px;
      // &--root {
      //   width: 100vw;
      //   max-height: 665px;
      // }
    }

    .d-swiper-navigation {
      .swiper-button-next.d-swiper__next,
      .swiper-button-prev.d-swiper__prev {
        min-width: 56px;
        height: 56px;
        border: none;
        background-color: @white;
        right: 50px;

        @media @mm {
          right: 25px;
        }

        @media @s {
          width: 35px;
          height: 35px;
          min-width: auto;
          right: 10px;
          // top: 65%;
        }
      }

      .swiper-button-prev.d-swiper__prev {
        left: 50px;

        @media @mm {
          left: 25px;
        }

        @media @s {
          left: 10px;
        }
      }
    }
  }

  &__productsFilter {
    position: relative;

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      width: 830px;
      height: 1790px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='831' height='1793' viewBox='0 0 831 1793' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cg clip-path='url(%23clip0_0_3)'%3e%3cg opacity='0.2'%3e%3cpath d='M0.140991 1790.87C77.943 1739.14 245.135 1583.29 291.483 1373.71C349.418 1111.74 -56.666 1296.98 123.317 986.25C303.3 675.52 549.071 757.372 463.076 366.451C377.081 -24.471 857 154.839 905.68 -116.778' stroke='%23161962'/%3e%3cpath d='M52.494 1791.77C130.297 1740.04 297.489 1584.19 343.837 1374.62C401.772 1112.64 -4.31198 1297.89 175.671 987.16C355.654 676.426 601.424 758.279 515.429 367.357C429.435 -23.565 909.35 155.746 958.03 -115.872' stroke='%2330E986'/%3e%3cpath d='M104.85 1792.68C182.652 1740.95 349.844 1585.1 396.192 1375.52C454.127 1113.55 48.043 1298.79 228.026 988.06C408.009 677.331 653.78 759.183 567.785 368.261C481.79 -22.661 961.71 156.65 1010.39 -114.968' stroke='%23E9BE2F'/%3e%3cpath d='M157.203 1793.58C235.006 1741.86 402.198 1586.01 448.546 1376.43C506.481 1114.46 100.397 1299.7 280.38 988.97C460.363 678.239 706.13 760.091 620.138 369.169C534.144 -21.753 1014.06 157.558 1062.74 -114.059' stroke='%23FE01CF'/%3e%3c/g%3e%3c/g%3e%3cdefs%3e%3cclipPath id='clip0_0_3'%3e%3crect width='831' height='1793' fill='white'/%3e%3c/clipPath%3e%3c/defs%3e%3c/svg%3e ");

      @media @l {
        right: -100px;
        width: 700px;
        height: 1550px;
      }

      @media @mm {
        display: none;
      }
    }
  }

  &__about {
    position: relative;
    margin-bottom: 120px;

    @media @s {
      margin-bottom: 35px;
    }

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 260px;
      left: 0;
      width: 1100px;
      height: 1650px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='1100' height='1905' viewBox='0 0 1100 1905' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cg clip-path='url(%23clip0_1_7)'%3e%3cg opacity='0.2'%3e%3cpath d='M37.1406 1904.87C114.943 1853.14 282.135 1697.29 328.483 1487.71C386.418 1225.74 -19.6659 1410.98 160.317 1100.25C340.3 789.52 586.071 871.372 500.076 480.451C414.081 89.5291 894 268.839 942.678 -2.77795' stroke='%23161962'/%3e%3cpath d='M89.4941 1905.77C167.297 1854.04 334.489 1698.19 380.837 1488.62C438.772 1226.64 32.6875 1411.89 212.671 1101.16C392.654 790.426 638.424 872.279 552.429 481.357C466.435 90.4351 946.353 269.746 995.032 -1.87195' stroke='%2330E986'/%3e%3cpath d='M141.85 1906.68C219.652 1854.95 386.844 1699.1 433.192 1489.52C491.127 1227.55 85.0432 1412.79 265.026 1102.06C445.009 791.331 690.78 873.183 604.785 482.261C518.79 91.339 998.709 270.65 1047.39 -0.968018' stroke='%23E9BE2F'/%3e%3cpath d='M194.203 1907.58C272.006 1855.86 439.198 1700.01 485.546 1490.43C543.481 1228.46 137.397 1413.7 317.38 1102.97C497.363 792.239 743.133 874.091 657.138 483.169C571.144 92.247 1051.06 271.558 1099.74 -0.05896' stroke='%23FE01CF'/%3e%3c/g%3e%3c/g%3e%3cdefs%3e%3cclipPath id='clip0_1_7'%3e%3crect width='1100' height='1905' fill='white'/%3e%3c/clipPath%3e%3c/defs%3e%3c/svg%3e ");

      @media @m {
        left: -100px;
        top: 350px;
      }

      @media @mm {
        display: none;
      }
    }

    & .d-wrapper {
      position: relative;
      display: flex;
      align-items: center;
      // flex-wrap: wrap;

      @media @s {
        display: flex;
        flex-direction: column-reverse;
      }
    }

    &-info {
      position: relative;
      margin-inline-end: 50px;
      width: 470px;

      @media @s {
        width: 100%;
        margin-inline-end: 0;
      }

      .title {
        font-style: normal;
        font-weight: 700;
        font-size: 56px;
        line-height: 62px;
        color: @darkBlue;
        margin-bottom: 30px;

        @media @s {
          text-align: center;
          font-size: 24px;
          margin-bottom: 10px;
        }
      }

      .desc {
        display: -webkit-box;
        -webkit-line-clamp: 6;
        -webkit-box-orient: vertical;
        overflow: hidden;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 170%;
        margin-bottom: 44px;
        min-height: 90px;
        // height: 100px;
        transition: all linear 0.3s;
        color: @black;

        &.active {
          transition: all linear 0.3s;
          overflow: visible;
          -webkit-line-clamp: unset;
        }
      }
    }

    &-img {
      // flex: 1 0 500px;
      // width: calc(1010 / 2 * 100vw);
      position: relative;
      width: 840px;
      max-height: 710px;

      @media @s {
        width: 100%;
        height: 100%;
      }

      .img {
        width: 100%;
        height: 100%;
        border-radius: 12px;
      }
    }

    &-btn.ant-btn-primary {
      background: @white;
      border: 1px solid @lightBlue;
      color: @lightBlue;
      font-weight: 600;
      font-size: 18px;

      @media @s {
        width: 100%;
      }

      &:hover {
        background: @lightBlue;
        color: @white;
      }
    }
  }
}
